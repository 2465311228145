export default getters => ({
  data () {
    return {
      loadingData: false
    }
  },
  created () {
    // TODO keep track of loading invocations to handle overlapping loads and loading state
    const reloadOnChange = () => {
      if (this.$options.reloadData instanceof Function) {
        this.loadingData = true
        const maybePromise = this.$options.reloadData.call(this)
        const promise = maybePromise && maybePromise.then instanceof Function
          ? maybePromise
          : Promise.resolve()

        promise.then(() => {
          this.loadingData = false
        })
      }
    }

    const watchDependency = getter => this.$store.watch(getter, reloadOnChange)

    const cancellations = getters.map(watchDependency)

    this.$_reloadDataMixin_cancellations = (this.$_reloadDataMixin_cancellations || []).concat(cancellations)

    this.loadingData = true
    let promise = this.$options.loadInitialData instanceof Function
      ? this.$options.loadInitialData.call(this)
      : Promise.resolve()

    if (this.$options.reloadData instanceof Function) {
      promise = promise.then(() => this.$options.reloadData.call(this, true))
    }
    promise.then(() => {
      this.loadingData = false
    })
  },
  beforeDestroy () {
    this.$_reloadDataMixin_cancellations.forEach(cancel => cancel())
    this.$_reloadDataMixin_cancellations = []
  }
})
