<template lang="html">
  <FrsModuleRoot
    :module="module"
    :module-path="['fieldRecordSystem', 'pendingZoneMapOrders']"
    :translation-namespaces="['pzmo']"
    :setup="loadPendingOrders"
  >
    <FrsRouterBackLink target="orgDashboard" />
    <PendingZoneMapOrders />
  </FrsModuleRoot>
</template>

<script>
import {mapActions} from 'vuex'

import FrsModuleRoot from '@frs/components/base/FrsModuleRoot'
import FrsRouterBackLink from '@frs/components/base/FrsRouterBackLink'

import PendingZoneMapOrders from './PendingZoneMapOrders'

export default {
  components: {
    FrsModuleRoot,
    FrsRouterBackLink,
    PendingZoneMapOrders
  },
  computed: {
    module () {
      return () => import('@store/modules/field-record-system/pending-zone-map-orders')
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/pendingZoneMapOrders', [
      'loadPendingOrders'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
