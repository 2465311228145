<template>
  <div class="pending-zone-map-orders">
    <h3>
      <IxRes>frs.pzmo.pendingZoneMapOrders.header.pendingZoneMapOrders</IxRes>
    </h3>
    <SimpleTable :columns="columns" :data="pendingOrders">
      <template #header-orderId>
        <IxRes>frs.pzmo.pendingZoneMapOrders.grid.headers.orderId</IxRes>
      </template>
      <template #header-timestamp>
        <IxRes>frs.pzmo.pendingZoneMapOrders.grid.headers.timeOfOrder</IxRes>
      </template>
      <template #header-field>
        <IxRes>frs.pzmo.pendingZoneMapOrders.grid.headers.field</IxRes>
      </template>
      <template #header-area>
        <IxRes>frs.pzmo.pendingZoneMapOrders.grid.headers.area</IxRes>
      </template>
      <template #header-orgUnitName>
        <IxRes>frs.pzmo.pendingZoneMapOrders.grid.headers.orgUnitName</IxRes>
      </template>
    </SimpleTable>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import SimpleTable from '@components/table/SimpleTable'

import ReloadDataOnOrgUnitChange from '@frs/mixins/ReloadDataOnOrgUnitChange'

export default {
  components: {
    SimpleTable
  },
  mixins: [
    ReloadDataOnOrgUnitChange
  ],
  computed: {
    ...mapState('fieldRecordSystem/pendingZoneMapOrders', {
      pendingOrders: state => Object.values(state.pendingOrders)
    }),
    columns () {
      return {
        orderId: {},
        timestamp: {type: 'datetime', sortable: true},
        field: {sortable: true},
        area: {},
        orgUnitName: {sortable: true}
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/pendingZoneMapOrders', [
      'loadPendingOrders'
    ])
  },
  reloadData () {
    return this.loadPendingOrders()
  }
}
</script>

<style>

</style>
