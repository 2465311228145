import { render, staticRenderFns } from "./PendingZoneMapOrdersModuleRoot.vue?vue&type=template&id=a031194a&scoped=true&lang=html&"
import script from "./PendingZoneMapOrdersModuleRoot.vue?vue&type=script&lang=js&"
export * from "./PendingZoneMapOrdersModuleRoot.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a031194a",
  null
  
)

export default component.exports