<template lang="html">
  <div class="back-link">
    <a href="#" @click.prevent="goBackToTarget">
      <slot name="text">
        <IxRes>frs.common.back</IxRes>
      </slot>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    target: {
      type: String,
      required: true
    }
  },
  methods: {
    goBackToTarget () {
      this.$router.push({name: this.target})
    }
  }
}
</script>

<style lang="scss" scoped>
.back-link {
  padding: 1em;
  background-color: white;
  border-bottom: 1px solid #c7c7c7;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.1);

  > a {
    text-decoration: underline;
  }
}
</style>
